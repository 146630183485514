import {useEffect}  from 'react'
import "./Home.css";

import Hero from "../../Components/Parts/Hero/Hero";
import Aboutus from "../../Components/Parts/Aboutus/Aboutus";
import Services from "../../Components/Parts/Services/Services";
import Products from '../../Components/Parts/Products/Products';
import Demobar from '../../Components/Parts/Demobar/Demobar';
import Team from '../../Components/Parts/Team/Team.jsx';
import Contactus from '../../Components/Parts/Contactus/Contactus.jsx';
import CEOM from '../../Components/Parts/CEO.jsx';
import Client from '../../Components/Parts/Clientcount/Client.jsx';

const Home = () => {
  useEffect(() =>{
    document.title="Quaid Soft | ERP Software Company In Pakistan &#8211; A Software Development &amp; IT CO";
},[]);
  return (
    <>
    <div className='hero1'>
    <Hero/>
    </div >
    <div className='courses container-xxl'>
    <div className='p-4'>
  
     <h2 className='h3e p-2'>About Quaid Soft</h2>    
    
      <Aboutus/>
    </div>
    <hr className='row '/>
      <CEOM/>
    
    <div className='courses '>
      <Client/>
    </div>
<hr className='row mt-4'/>
    <div className='p-4 courses '>
      <Services/>
    </div>
    </div>
    <hr className='row'/>
    <div className="container-xxl courses  my-2 py-01 pb-2 p-2">
      <div className='row p-4'>
                <div  className="wow fadeInUpp-1 erpimg "  data-wow-delay="0.3s"><h3>ERP Modules For Housing & Buildings Projects</h3>
                </div>
                     <img  className="mx-auto imgg" src={process.env.PUBLIC_URL + '/Images/Sliders/Property-software-real-estate-software-Pakistan-property-management-software-Pakistan-sales-management-software-Pakistan-property-management-software-real-estate-software.jpg'}  alt="Pakistan Top Real Estate Software Company, Top Ten Real Estate Software, Developers Management System, Building Sales Management System, Society Billing System, Maintenance Management System, Construction Management System, Open Form Trading System, Open Form QR Code Printing, QR Code Printing on Open Form, Real Estate File Trading System, Islamabad Housing Scheme Software"/>
                     </div>
        </div>

        <hr className='row'/>
      <h2 class="text-center">Products</h2>    
      <Products/>
      <div className='container-xxl courses '>
      <div className='p-4 row mt-0'>
      <Demobar/>
    </div>
      </div>
    
    <div className='p-2 courses '>
    <div class="text-center" >
      <h2 className='h3e'>Our Team</h2>    
       </div>
      <Team/>
    </div>
    <hr className='row'/>
    <div className='p-2 courses '>
    <div class="text-center" >
      <h2 className='h3e'>Contact Us</h2>    
       </div>
      <Contactus/>
    </div>
    
  
    </>
  )
}

export default Home