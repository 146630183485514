import {useEffect} from 'react';
import'./Team.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
const Team = () => {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <>    <div className="container-xxl py-2 " >  
        <div className="row p-4" data-aos="zoom-in-up"  >
            <div className="row g-0 team-items justify-content-center">
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/malik-khurram.jpg'} alt=""/>
                            <div className="team-social text-center">
           <h5 >Malik Khuram<br/><span className="team1">System Analyst</span></h5> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/sarwat-raheem_quaidsoft.png'} alt="sarwat-raheem(quaidsoft)"/>
                            <div className="team-social text-center">
                                <h5 >Sarwat Rahim<br/><span className="team1">GM Sales (Punjab)</span></h5>    
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Shiraz Ali.jpg'} alt=""/>
                            <div className="team-social text-center">
                         <h5 >Shiraz Ali <br/><span className="team1">GM Sales (KPK)</span></h5> 
                            </div>
                        </div>
                       
                    </div>
                </div>


                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/waseem-zahid.jpg'} alt=""/>
                            <div className="team-social text-center">
                                <h5 >Waseem Zahid <br/><span className="team1">GM Sales (KSA)</span></h5> 
                            </div>
                        </div>
                    </div>
                </div>

             
            
            </div>
        </div> 
        <div className="row p-4 "data-aos="zoom-in-up">
            <div className="row g-0 team-items justify-content-center">
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="team-item position-relative">
                <div className="position-relative">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/KhushbakhtMazhar.png'} alt="asadkasana"/>
                    <div className="team-social text-center">
                        <h5 >Khushbakht Mazhar<br/><span className="team1">Sales Executive</span></h5> 
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="team-item position-relative">
                <div className="position-relative">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Irtaza Quaidsoft.png'} alt=""/>
                    <div className="team-social text-center">
                        <h5 >M. Irtaza Akhtar<br/><span className="team1">Sr. SQA / Support Engr.</span></h5> 
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="team-item position-relative">
                <div className="position-relative">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/asadkasana.png'} alt="asadkasana"/>
                    <div className="team-social text-center">
                        <h5 >M. Asad Kissana<br/><span className="team1">Software Engineer</span></h5> 
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid"src={process.env.PUBLIC_URL + '/Images/Team/Talha Mehmood(web developer).jpg'} alt=""/>
                            <div className="team-social text-center">
                                <h5 >Talha Mehmood<br/><span className="team1">Sr. Software/Web Developer</span></h5> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row p-4"data-aos="zoom-in-up">
    <div className="row g-0 team-items justify-content-center">
    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
            <div className="team-item position-relative">
                <div className="position-relative">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Aqsad.jpg'} alt=""/>
                    <div className="team-social text-center">
                        <h5>Aqsad Javeed<br/><span className="team1">Sr. Software Engineer</span></h5>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-3 col-md-6 wow fadeInUp rad"  data-wow-delay="0.7s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Muhammad Umair.png'} alt=""/>
                            <div className="team-social text-center">
                                <h5 >Muhammad Umair<br/><span className="team1">Mobile App Developer</span></h5> 
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/zeeshan-tariq.jpg'} alt=""/>
                            <div className="team-social text-center">
                                <h5 >Zeeshan Tariq<br/><span className="team1">Software / Web Developer</span></h5>    
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/atif-shahzad.jpg'} alt=""/>
                            <div className="team-social text-center">
                                <h5 >Atif Shahzad<br/><span className="team1">Software Engineer</span></h5> 
                            </div>
                        </div>
                    </div>
                </div>

       

    </div>
        </div>
        <div className="row p-4"data-aos="zoom-in-up">
    <div className="row g-0 team-items justify-content-center">     
    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="team-item position-relative">
                <div className="position-relative">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Usmanquaidsoft.png'} alt=""/>
                    <div className="team-social text-center">
                        <h5 >Muhammad Usman<br/><span className="team1">Sr. SQA / Support Engr.</span></h5> 
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="team-item position-relative">
                <div className="position-relative">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Mahmood_baig.jpg'} alt=""/>
                    <div className="team-social text-center">
                        <h5 >Mahmood Baig<br/><span className="team1">SQA / Support Engr.</span></h5> 
                    </div>
                </div>
            </div>
        </div>


    
    </div>
        </div>
        </div>
    </>

  );
}

export default Team;