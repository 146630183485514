import React from 'react'
import "./Footer.css";
import { Link } from 'react-router-dom';
const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
  return (
    <>
    <div className="container-xxl text-light footer my-2 mb-0 py-5 wow fadeIn bgcolor " data-wow-delay="0.1s">
        <div className="container ">
            <div className="row g-6">
            
            <div className="col-lg-3 col-md-6">
            <h3 className="text-light mb-2">Quick Links</h3>
                    <Link onClick={scrollToTop} to="/"  className="btn btn-link" ><i className="bi bi-caret-right-fill me-2"></i>Home</Link><br/>                   
                    <Link onClick={scrollToTop} to="/aboutus" className="btn btn-link" ><i className="bi bi-caret-right-fill me-2"></i>About us</Link><br/>
                    <Link onClick={scrollToTop} to="#product1" className="btn btn-link" ><i className="bi bi-caret-right-fill me-2"></i>Products</Link><br/>
                <Link onClick={scrollToTop} to="/Portfolio" className="btn btn-link" ><i className="bi bi-caret-right-fill me-2"></i>Portfolio</Link><br/>
                <Link onClick={scrollToTop} to="/Team"  className="btn btn-link"><i className="bi bi-caret-right-fill me-2"></i>Team</Link><br/>
                <Link onClick={scrollToTop} to="/Careers" className="btn btn-link"><i className="bi bi-caret-right-fill me-2"></i>Careers</Link><br/>
                <Link onClick={scrollToTop} to="/Blog" className="btn btn-link" ><i className="bi bi-caret-right-fill me-2"></i>Blog</Link><br/>
                <Link onClick={scrollToTop} to="/Contactus" className="btn btn-link"><i className="bi bi-caret-right-fill me-2"></i>Contact</Link><br/>
                <Link onClick={scrollToTop} to="/Support" className="btn btn-link"><i className="bi bi-caret-right-fill me-2"></i>Support</Link><br/>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h3 className="text-light mb-2">Popular Links</h3>
                    <Link className="btn btn-link" onClick={scrollToTop} to="/software-development"> <i className="bi bi-caret-right-fill me-2"></i>Software Development</Link>
                    <Link className="btn btn-link"onClick={scrollToTop} to="/web-development"><i className="bi bi-caret-right-fill me-2"></i>Web Development</Link>
                    <Link className="btn btn-link" onClick={scrollToTop} to="/mobile-application-development"><i className="bi bi-caret-right-fill me-2"></i>Mobile App Development</Link>
                    <Link className="btn btn-link" onClick={scrollToTop} to="/project-consultancy"><i className="bi bi-caret-right-fill me-2"></i>Project Consultancy</Link>
                    <Link className="btn btn-link" onClick={scrollToTop} to="/hardware-and-networking"><i className="bi bi-caret-right-fill me-2"></i>Hardware/Network Services</Link>
                    <Link className="btn btn-link" onClick={scrollToTop} to="/digital_marketing"><i className="bi bi-caret-right-fill me-2"></i>Digital Marketing</Link>
                    <Link className="btn btn-link" onClick={scrollToTop} to="/shoots-and-tv-commercials"><i className="bi bi-caret-right-fill me-2"></i>Shoots & Tv Commercial</Link>
                    <Link className="btn btn-link" onClick={scrollToTop} to="/domain-and-hosting"><i className="bi bi-caret-right-fill me-2"></i>Domain & Hosting</Link><br/>
                    <Link className="btn btn-link" onClick={scrollToTop} to="/cctv-cameras" ><i className="bi bi-caret-right-fill me-2"></i>CCTV Camera</Link>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h3 className="text-light text-decoration-none mb-2">Contact Us</h3>
                   <Link className="text-light text-decoration-none mb-2" onClick={scrollToTop} to="tel:+923214812006" target="_blank" ><i className="fa fa-phone me-3"></i>+92 321 481 2006</Link><br/>
                   <Link className="text-light text-decoration-none mb-2" onClick={scrollToTop} to="tel:+923334812006" target="_blank" ><i className="fa fa-phone me-3"></i>+92 333 481 2006</Link><br/>
                   <Link className="text-light text-decoration-none mb-2" onClick={scrollToTop} to="tel:+923224005771" target="_blank" ><i className="fa fa-phone me-3"></i>+92 322 400 5771</Link><br/>                  
                   <Link className="text-light text-decoration-none mb-2" onClick={scrollToTop} to="tel:+923334900984" target="_blank" ><i className="fa fa-phone me-3"></i>+92 333 490 0984</Link><br/>
                    <Link className="text-light text-decoration-none mb-2" onClick={scrollToTop} to="mailto:info@quaidsoft.com" target="_blank" ><i className="fa fa-envelope me-3"></i>info@quaidsoft.com</Link><br/>
                    <Link className="text-light text-decoration-none mb-2 "  target="_blank" onClick={scrollToTop} to="https://www.quaidsoft.com"><i className="fa fa-chrome me-3"></i>www.quaidsoft.com</Link>
                    <div className="d-flex pt-2">
                        <Link className="btn btn-square btn-outline-light me-1" target="_blank"  onClick={scrollToTop} to="https://api.whatsapp.com/send?phone=+923214812006&text=Hello%21%20QuaidSoft"><i className="bi bi-whatsapp"></i></Link>
                        <Link className="btn btn-square btn-outline-light me-1" target="_blank"  onClick={scrollToTop} to="https://www.facebook.com/quaidsofttech"><i className="fab fa-facebook-f"></i></Link>
                        <Link className="btn btn-square btn-outline-light me-1" target="_blank" onClick={scrollToTop} to="https://twitter.com/QuaidSoft"><i className="fab fa-twitter"></i></Link>
                        <Link className="btn btn-square btn-outline-light me-1"  target="_blank" onClick={scrollToTop} to="https://www.linkedin.com/company/quaid-soft/about/"><i className="fab fa-linkedin-in"></i></Link>
                        <Link className="btn btn-square btn-outline-light me-1" target="_blank"  onClick={scrollToTop} to="https://www.instagram.com/quaidsoft/"><i className="fab fa-instagram"></i></Link>
                        <Link className="btn btn-square btn-outline-light me-1" target="_blank"  onClick={scrollToTop} to="https://youtube.com/@quaidsoft"><i className="fab fa-youtube"></i></Link>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h3 className="text-light mb-2">Address</h3>
                    <div className='disf'>
                        <i className="fa fa-map-marker"></i>
                        <div className="mb-2 ref">Office No. 325, 3rd Floor,<br/>Al Hafeez Shopping Mall,<br/>Main Boulevard Gulberg-III,<br/> Lahore, Pakistan</div>
                    </div>  
                </div>
            </div>   
            </div>
            </div>
            <div className="container-xxl copyright text-light py-4 wow fadeIn bgorg"  data-wow-delay="0.1s">
        <div className="container">
            <div className="row">
                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                Copyright © 2023 Quaid Soft | ERP Software Company In Pakistan
                </div>
                <div className="col-md-6 text-center text-md-end">
                    Designed By <Link className='text-white' onClick={scrollToTop} to="https://quaidsoft.com">Quaidsoft</Link>
                </div>
            </div>
            </div>
            </div>
    </>
  );
}

export default Footer;